<template>
  <div class="news_wrapper w">
    <div
      v-if="componentInfo.config.showTitle"
      class="news_title"
    >
      <div class="news_title_name">
        <index-title-icon />
        <span>{{ componentInfo.config.title }}</span>
      </div>
      <NuxtLink to="/article/list">
        <div
          v-if="componentInfo.config.showMore"
          class="news_title_more pointer"
        >
          更多<i class="arrows" />
        </div>
      </NuxtLink>
    </div>
    <div class="new_main">
      <NuxtLink
        v-for="item in componentInfo.data"
        :key="item.id"
        :to="'/article/view?id=' + item.id"
        class="news_item_wrapper"
      >
        <div class="news_item">
          <div class="news_info">
            <div class="news_name tx-ov-2">
              {{ item.title }}
            </div>
            <div class="news_desc tx-ov-2">
              {{ item.summary }}
            </div>
            <div class="new_info_desc">
              <span>{{ item.readCount + "人已阅" }}</span>
              <span class="border" />
              <span>{{ item.gmtCreate }}</span>
            </div>
          </div>
          <div
            v-if="item.cover"
            class="item_image_wrapper"
          >
            <img
              class="item_image"
              :src="item.cover"
            >
          </div>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    componentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, { emit }) {
    return {}
  }
})
</script>
<style lang="scss" scoped>
.news_wrapper {
  padding: 25px 0px;
}
.news_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  line-height: 22px;
  color: #333;
}
.news_title_name {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  img {
    width: 24px;
    height: 24px;
  }
}
.new_main {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.news_item_wrapper {
  margin-bottom: 20px;
  padding: 0 10px;
  width: 50%;
}
.news_item_wrapper:nth-last-child(-n + 2) {
  margin-bottom: 0;
}
.news_item {
  padding: 15px;
  display: flex;
  background: #ffffff;
  border-radius: 5px;
  &:hover {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transition: all 0.3s;
  }
}
.item_image_wrapper {
  overflow: hidden;
  height: 80px;
  width: 144px;
  border-radius: 6px;
  .item_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.news_info {
  flex: 1;
  padding-right: 10px;
}
.news_name {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color:#333;
  -webkit-line-clamp: 1;
}
.news_desc {
  padding-top: 5px;
  font-size: 12px;
  line-height: 18px;
  height: 42px;
  color: #999999;
}
.new_info_desc {
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  color: #999999;
  .border {
    background: #becadb;
    display: inline-block;
    height: 10px;
    margin: 0 10px;
    width: 1px;
  }
}
</style>
