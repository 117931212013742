<template>
  <div class="teacher_wrapper w">
    <div
      v-if="componentInfo.config.showTitle"
      class="teacher_title"
    >
      <div class="teacher_title_name">
        <index-title-icon />
        <span>{{ componentInfo.config.title }}</span>
      </div>
      <NuxtLink to="/teacher/list">
        <div
          v-if="componentInfo.config.showMore"
          class="teacher_title_more pointer"
        >
          更多<i class="arrows" />
        </div>
      </NuxtLink>
    </div>
    <div
      v-if="teacherChunk.length > 0"
      class="banner"
    >
      <div
        class="banner-content"
        @mouseout="mout"
        @mouseover="mover"
      >
        <ul
          class="banner-list"
          style="height: 264px"
        >
          <li
            v-for="(teacherList, index) in teacherChunk"
            :key="index"
            class="item"
            :class="{ on: index === num }"
          >
            <NuxtLink
              v-for="teacher in teacherList"
              :key="teacher.id"
              :to="'/teacher/view?id=' + teacher.id"
              class="teacher_item_wrapper"
            >
              <div class="teacher_item">
                <div class="teacher_item_hover">
                  <span :class="{'ov-tx-9':teacher.introduce.length > 150}">{{ teacher.introduce || '暂无简介' }}</span>
                </div>
                <div
                  v-if="teacher.headImg"
                  class="item_image_wrapper item_image"
                >
                  <img :src="teacher.headImg">
                </div>
                <div class="teacher_name tx-ov">
                  {{ teacher.nickname }}
                </div>
                <!-- <div class="teacher_info">
                      <span>课程{{ ' ' + (teacher.courseCount || 0) }}</span>
                       <span class="border" />
                      <span>班级{{ ' ' + (teacher.classroomCount || 0) }}</span>
                    </div> -->
                <div class="teacher_intro tx-ov-2">
                  <p>{{ teacher.introduce || '暂无简介' }}</p>
                </div>
              </div>
            </NuxtLink>
          </li>
        </ul>
        <ul class="page_dots">
          <span
            v-for="(item, index) in teacherChunk"
            :key="index"
            class="dots"
            :class="{ on: index === num }"
            @mouseenter="num = index"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { chunk } from 'lodash-es'
export default defineComponent({
  props: {
    componentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, { emit }) {
    const teacherChunk = computed(() => {
      return chunk(props.componentInfo.data, 5)
    })
    const num = ref(0)
    const interval = ref(null)
    const change = () => {
      interval.value = setInterval(() => {
        if (num.value + 1 >= teacherChunk.value.length) {
          num.value = 0
        } else {
          num.value++
        }
      }, 5000)
    }
    const mout = () => {
      change()
    }
    const mover = () => {
      clearInterval(interval.value)
    }
    return {
      num,
      interval,
      mout,
      mover,
      teacherChunk
    }
  }
})
</script>
<style lang="scss" scoped>
.teacher_wrapper {
  padding: 25px 0px;
  .border {
    background: #becadb;
    display: inline-block;
    height: 10px;
    margin: 0 10px;
    width: 1px;
  }
}
.teacher_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  line-height: 22px;
  color: #333;
}
.teacher_title_name {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  img {
    width: 24px;
    height: 24px;
  }
}
.teacher_title_more {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
}
.more_img {
  margin-left: 4px;
}
.teacher_item_wrapper {
  height: 100%;
  width: 20%;
  padding: 0px 10px;
  position: relative;
}
.teacher_item {
  padding-top: 24px;
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 5px;
   &:hover {
     .teacher_item_hover {
       display: flex;
       align-items: center;
       justify-content: center;
       top: 0;
       padding: 10px;
     }
     .teacher_item_hover .ov-tx-9{
       font-weight: 400;
       font-size: 14px;
       line-height: 25px;
       text-overflow: ellipsis;
       display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
       -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
       -webkit-line-clamp: 9; /** 显示的行数 **/
       overflow: hidden; /** 隐藏超出的内容 */
     }
   }
}
.teacher_item_hover {
  position: absolute;
  left: 0;
  top: 120%;
  width: 100%;
  transition: all 0.3s;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  display: none;
  z-index: 99;
  color: #FFFFFF;
}
.item_image_wrapper {
  width: 116px;
  height: 116px;
  position: relative;
  overflow-y: auto;
}
.item_image {
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
}
.teacher_name {
  padding: 0 20px;
  margin-top: 18px;
  font-weight: 500;
  color:#333;
  font-size: 16px;
  line-height: 20px;
}
.teacher_info {
  margin-top: 10px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
}
.banner {
  position: relative;
  .banner-content {
    margin: 0 auto;
    position: relative;
    // background: #e3e3e3;
  }
  ul {
    position: relative;
  }
  .banner-list {
  }
  .item {
    display: flex;
    margin-left: -10px;
    margin-right:-10px;
    opacity: 0;
    height: 100%;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    transition: all 1s;
    &.on {
      opacity: 1;
      z-index: 10;
    }

  }
  .page_dots {
    cursor: pointer;
    margin-top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px 0;
    border-radius: 13px;
    height: 26px;
    // background: rgba(0, 0, 0, 0.2);
    &.old {
      left: 0;
      right: 0;
      margin-right: 0;
      bottom: 30px;
    }
    .dots {
      display: inline-block;
      width: 8px;
      height: 6px;
      margin: 0 4px;
      background: #BECADB;
      border-radius: 15px;
      &.on {
        width: 14px;
        background: #2256F6;
      }
    }
  }

}
.teacher_intro{
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
